import {
  CookieServiceOptions,
  createEnsightenConsentManager,
} from '@oneaudi/audi-cookie-service';
import {
  Platform,
  getMetaContent,
  getPlatform,
  getPreviewToken,
  getPrimaryConsumerId,
} from './page-content';
import { AudiColaServiceOptions } from '@oneaudi/onegraph-service-v4';
import { AudiConfiguratorLinkServiceOptions } from '@oneaudi/configurator-link-service';
import { AudiMarketContextServiceOptions } from '@volkswagen-onehub/audi-market-context-service';
import { AuthServiceOptions } from '@oneaudi/audi-auth-service';
import { CarlineLinkServiceOptions } from '@oneaudi/carline-link-service';
import { CurrencyFormatterConfig } from '@oneaudi/number-formatter-service';
import { CurrentCarlineServiceConfig } from '@oneaudi/current-carline-service';
import { DisclaimerManagerOptions } from '@volkswagen-onehub/disclaimer-manager';
import { EnvConfigServiceOptions } from '@oneaudi/audi-env-config-service';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { I18NServiceOptions } from '@oneaudi/i18n-service';
import { INTEGRATOR_ID } from '../constants';
import { LocaleServiceConfig } from '@volkswagen-onehub/locale-service';
import { NavigationServiceOptions } from '@volkswagen-onehub/navigation-service';
import { OneGraphServiceOptions } from '@oneaudi/onegraph-service';
import { RenderMode } from '@oneaudi/render-mode-service';
import type { RootLocationTransformer } from '@oneaudi/feature-services';
import { StateManagerServiceOptions } from '@oneaudi/confi-state-manager-service';
import { TrackingServiceOptions } from '@oneaudi/audi-tracking-service';
import { VueFormatterServiceOptions } from '@oneaudi/vue-formatter-service';

import { createRootLocationTransformer as createFalconRootLocationTransformer } from '@oneaudi/feature-services';
import { createFallbackConsentManager } from './fallback-consent-manager';
import { createLogger } from '../logger';
import { createRootLocationTransformer } from './service-configuration-history';
import { getSysEnv } from './sysenv';
import { removePrivateData } from './tracking-url-sanitizer';

const getDefaultLogoutRedirectUrl = (dom: Element): string => {
  const logoutUrl = getMetaContent(dom, 'myaudi-logout-url');
  if (!logoutUrl) {
    return location.href;
  }

  return logoutUrl;
};

const getApiGatewayUrl = (dom: Element): string =>
  getMetaContent(dom, 'wag-baseurl');

const getAuthProxyUrl = (dom: Element): string => {
  const myAudiAuthProxy = getMetaContent(dom, 'myaudi-auth-proxy');
  if (myAudiAuthProxy) {
    return myAudiAuthProxy;
  }
};

const getAuthOptions = (dom: Element): AuthServiceOptions => {
  // get proxy URL
  const authProxyUrl = getAuthProxyUrl(dom);

  // get logout URL
  const defaultLogoutRedirectUrl = getDefaultLogoutRedirectUrl(dom);

  return {
    authProxyUrl,
    defaultLoginRedirectUrl: location.href,
    defaultLogoutRedirectUrl,
    fagScopeMappings: new Map<string, string[]>([['myaudi', []]]),
    ssoUrl: location.href,
  };
};

const getDpuApiGatewayUrl = (dom: Element): string => {
  const apiGateWayUrl = getApiGatewayUrl(dom);
  const localeServiceDefinition = getLocaleOptions(dom);
  const language = localeServiceDefinition.language;
  const country = localeServiceDefinition.countryCode.toLowerCase();

  return `${apiGateWayUrl}/dpu/v1/${country}/${language}`;
};

const getMarketContextApiGatewayUrl = (dom: Element): string => {
  const apiGateWayUrl = getApiGatewayUrl(dom);
  const localeServiceDefinition = getLocaleOptions(dom);
  const language = localeServiceDefinition.language;
  const country = localeServiceDefinition.countryCode.toLowerCase();

  return `${apiGateWayUrl}/market-context/v1/${country}/${language}`;
};

const getOneGraphOptions = (dom: Element): OneGraphServiceOptions => {
  const dpuApiGatewayUrl = getDpuApiGatewayUrl(dom);
  const oneGraphOptions = {
    enableBatman: getMetaContent(dom, 'bat-signal') === 'true',
    oneGraphDataVersionsUrl: `${dpuApiGatewayUrl}/versions`,
    oneGraphUrl: getMetaContent(dom, 'cola-api-url'),
    ...getPreviewJwtTokenOption(dom),
  };

  return oneGraphOptions;
};

const getPreviewJwtTokenOption = (
  dom: Element,
): AudiColaServiceOptions | OneGraphServiceOptions => {
  const jwtToken = getPreviewToken(dom);
  if (jwtToken) {
    return { preview: { jwtToken: jwtToken } };
  }

  return {};
};

const getColaOptions = (dom: Element): AudiColaServiceOptions => {
  const dpuApiGatewayUrl = getDpuApiGatewayUrl(dom);
  const colaOptions = {
    colaDataVersionsUrl: `${dpuApiGatewayUrl}/versions`,
    colaUrl: getMetaContent(dom, 'cola-api-url'),
    ...getPreviewJwtTokenOption(dom),
  };

  return colaOptions;
};

const getConfiguratorLinkOptions = (
  dom: Element,
): AudiConfiguratorLinkServiceOptions => {
  const apiGateWayUrl = getApiGatewayUrl(dom);
  const localeServiceDefinition = getLocaleOptions(dom);
  const language = localeServiceDefinition.language;
  const country = localeServiceDefinition.countryCode.toLowerCase();

  return {
    apiGateWayUrl: `${apiGateWayUrl}/carlinelinks/v1/${country}/${language}`,
  };
};

const getCurrentCarlineOptions = (
  dom: Element,
): CurrentCarlineServiceConfig => {
  return {
    carline: getMetaContent(dom, 'carline'),
    carlineGroup: getMetaContent(dom, 'carlinegroup'),
    prstring: getMetaContent(dom, 'prstring'),
  };
};

const getEnvConfigOptions = (dom: Element): EnvConfigServiceOptions => {
  const sysEnv = getSysEnv(getApiGatewayUrl(dom));
  const envConfigBaseUrl = 'https://assets.audi.com/audi-env-config/0';

  // country specific cases
  const localeServiceDefinition = getLocaleOptions(dom);

  let templateUrl = `${envConfigBaseUrl}/config/${sysEnv}/{key}.json`;
  // us / AoA?
  if (localeServiceDefinition.countryCode.toLowerCase() === 'us') {
    templateUrl = `${envConfigBaseUrl}/config/aoa/${sysEnv}/{key}.json`;
  }

  return {
    templateUrl,
  };
};

const gfaServicesDefaultConfigurationGfaScope = {
  gfa: {},
};

const gfaServicesDefaultConfigurationOneshopBffPart = {
  id: '?',
  origin: window.location.origin,
  path: '/',
};

const gfaServicesDefaultConfigurationOneshopUbffPart = {
  id: '?',
  origin: window.location.origin,
  path: '/',
};

const gfaServicesDefaultConfiguration = {
  oneshopBff: { ...gfaServicesDefaultConfigurationOneshopBffPart },
  oneshopUbff: { ...gfaServicesDefaultConfigurationOneshopUbffPart },
};

const getGfaServiceConfig = (): GfaServiceConfigProviderV1 => {
  const logger = createLogger();

  if (typeof window.SETUPS === 'undefined') {
    logger.info(
      '`window.SETUPS` is not defined. Some services may not retrieve data.',
    );
    return { gfa: { ...gfaServicesDefaultConfiguration } };
  }

  const gfaServiceConfig = window.SETUPS.get('gfaServiceConfig');

  if (typeof gfaServiceConfig !== 'string' || gfaServiceConfig === '') {
    return { gfa: { ...gfaServicesDefaultConfiguration } };
  }
  try {
    let parsedConfiguration = JSON.parse(gfaServiceConfig);
    if (parsedConfiguration) {
      // ensure the default properties are available to avoid crashing the service
      parsedConfiguration = {
        ...gfaServicesDefaultConfigurationGfaScope,
        ...parsedConfiguration,
      };
      parsedConfiguration.gfa = {
        ...gfaServicesDefaultConfiguration,
        ...parsedConfiguration.gfa,
      };
      parsedConfiguration.gfa.oneshopBff = {
        ...gfaServicesDefaultConfigurationOneshopBffPart,
        ...parsedConfiguration.gfa.oneshopBff,
      };
      // Explicitly unset `oneshopGlobalFSBaseurl` to ensure usage of webpack's
      // automatic public path (see `webpack-config.js`) for loading One.Shop
      // feature service chunks.
      parsedConfiguration.gfa.oneshopGlobalFSBaseurl = undefined;
      parsedConfiguration.gfa.oneshopUbff = {
        ...gfaServicesDefaultConfigurationOneshopUbffPart,
        ...parsedConfiguration.gfa.oneshopUbff,
      };
      return parsedConfiguration;
    }

    return { gfa: { ...gfaServicesDefaultConfiguration } };
  } catch (err) {
    logger.error(err);
    return { gfa: { ...gfaServicesDefaultConfiguration } };
  }
};

const getDisclaimerManagerOptions = (): DisclaimerManagerOptions => {
  return {
    defaultLegalEntity: 'audi',
    legalEntityLabels: { audi: 'Audi' },
  };
};

const getI18nOptions = (dom: Element): I18NServiceOptions => {
  return {
    i18nEndpointUrl: getMetaContent(dom, 'i18n-endpoint'),
  };
};

const getGlobalSetupsValue = (varName: string): string | undefined => {
  return (
    (typeof window?.SETUPS?.get === 'function'
      ? window.SETUPS.get(varName)
      : undefined) || undefined
  );
};

const getLocaleOptions = (dom: Element): LocaleServiceConfig => {
  return {
    brand: 'A',
    countryCode: getMetaContent(dom, 'locale-market') || 'DE',
    currency: getMetaContent(dom, 'locale-currency') || 'EUR',
    language: getMetaContent(dom, 'locale-language') || 'de',
    market:
      getMetaContent(dom, 'locale-iso-3166-1-alpha3') ||
      getGlobalSetupsValue('nemo.locale.country.iso3'),
  };
};

const getMarketContextServiceOptions = (
  dom: Element,
): AudiMarketContextServiceOptions => {
  return {
    v2: {
      apiGatewayUrl: getMarketContextApiGatewayUrl(dom),
    },
  };
};

const getNavigationOptions: () => NavigationServiceOptions = () => {
  const logger = createLogger();

  // respect overriden navigation service options
  const navigationOptions =
    window.featureServiceConfigs?.['navigation-service'];
  if (Array.isArray(navigationOptions) && navigationOptions.length == 1) {
    return navigationOptions[0];
  }

  if (typeof window.SETUPS === 'undefined') {
    logger.info(
      '`window.SETUPS` is not defined. Some services may not retrieve data.',
    );
    return {};
  }

  const navigationServiceOptions = window.SETUPS.get(
    'navigationServiceOptions',
  );

  if (
    typeof navigationServiceOptions !== 'string' ||
    navigationServiceOptions === ''
  ) {
    return {};
  }
  try {
    return JSON.parse(navigationServiceOptions);
  } catch (err) {
    logger.error(err);
    return {};
  }
};

const getNumberFormatterOptions = (dom: Element): CurrencyFormatterConfig => {
  return {
    countryCode: getMetaContent(dom, 'locale-market'),
    currency: getMetaContent(dom, 'locale-currencyType') as
      | 'code'
      | 'narrowSymbol',
    currencyISOCode: getMetaContent(dom, 'locale-currencyISOCode'),
    language: getMetaContent(dom, 'locale-language'),
    showDecimals: 'true' === getMetaContent(dom, 'locale-showDecimals'),
  };
};

const getRenderModeOptions = (): RenderMode => {
  const renderMode = document.body.className.includes('cq-wcm-edit')
    ? RenderMode.EDIT
    : RenderMode.DEFAULT;
  return renderMode;
};

const getTrackingOptions = (): TrackingServiceOptions => {
  const logger = createLogger();

  return {
    datalayer: window.digitalData,
    primaryConsumerId: getPrimaryConsumerId(document.body),
    sanitizeUrl: (url: string): string => removePrivateData(url, logger),
  };
};

const getVueFormatterOptions = (dom: Element): VueFormatterServiceOptions => {
  let vueAgwEndpoint = getMetaContent(dom, 'vue-agw-endpoint');

  try {
    // test if the url is valid
    const url = new URL(vueAgwEndpoint);
    if (url.host) {
      if (!url.host.endsWith('audi.com')) {
        const logger = createLogger();
        logger.error(
          `invalid url for vue agw endpoint detected: ${vueAgwEndpoint}. Will fallback to default endpoint`,
        );
        vueAgwEndpoint = null;
      }
    }
  } catch (error) {
    const logger = createLogger();
    logger.error(
      `Error parsing url: ${vueAgwEndpoint}. Will fallback to default endpoint`,
      error,
    );
    vueAgwEndpoint = null;
  }

  // fallback to default url if no valid url is provided
  if (!vueAgwEndpoint) {
    vueAgwEndpoint = 'https://vue-agw.assets.audi.com';
  }

  const vueFormatterOptions: VueFormatterServiceOptions = {
    VUE_AGW_ENDPOINT: vueAgwEndpoint,
  };

  return vueFormatterOptions;
};

const prepareWindow = function (): void {
  window.featureServiceDefinitions = window.featureServiceDefinitions || [];

  // if there is no datalayer we provide one. as convenience for the developer
  if (typeof window.digitalData === 'undefined') {
    window.digitalData = { event: [] };
  }
};

const getCookieServiceOption = function (): CookieServiceOptions {
  const logger = createLogger();
  let useFallbackCookieManagement = true;
  if (typeof window.SETUPS !== 'undefined') {
    const scopes = window.SETUPS.get('nemo.env.scopes');
    useFallbackCookieManagement = scopes && scopes.includes('COOKIE_POLICY');
  } else {
    logger.warn(
      '`window.SETUPS` is not defined. Fallback to fallback consent manager',
    );
  }

  return {
    consentManager: useFallbackCookieManagement
      ? createFallbackConsentManager()
      : createEnsightenConsentManager(),
  };
};

const getCarlineLinkServiceConfig = function (
  dom: Element,
): CarlineLinkServiceOptions {
  const carlineLinkServiceOptions: CarlineLinkServiceOptions = {};
  carlineLinkServiceOptions.carlineLinkEndpointUrl = `${getApiGatewayUrl(
    dom,
  )}/carlinelinks/v1/${getLocaleOptions(dom).countryCode.toLowerCase()}/${
    getLocaleOptions(dom).language
  }`;
  return carlineLinkServiceOptions;
};

function getStateManagerConfig(dom: Element): StateManagerServiceOptions {
  const searchParams = new URLSearchParams(window.location.search);
  const debug = searchParams.has('debug');

  // TODO: correctly set the carlineID2Urls

  return {
    carlineID2Urls: { q4etron: { baseurl: '/' } },
    carlineId: getMetaContent(dom, 'carline'),
    debug,
    enableSimpleConflictResolution: searchParams.has(
      'simpleConflictResolution',
    ),
    enableSingleQueryMode: true,
  };
}

function getHistoryServiceConfig(
  domHead: Element,
  domBody: Element,
): RootLocationTransformer {
  const platform = getPlatform(domHead);
  const navigationServiceOptions = getNavigationOptions();

  if (platform === Platform.FALCON) {
    return createFalconRootLocationTransformer(
      INTEGRATOR_ID,
      getPrimaryConsumerId(domBody),
      navigationServiceOptions,
    );
  }

  let productListFAId = '';
  let productDetailsFAId = '';
  let cartFAId = '';
  let checkoutFAId = '';

  if (navigationServiceOptions.pages) {
    if (
      navigationServiceOptions.pages['product-list'] &&
      navigationServiceOptions.pages['product-list'].featureApps &&
      navigationServiceOptions.pages['product-list'].featureApps.details
    ) {
      productListFAId =
        navigationServiceOptions.pages['product-list'].featureApps.details;
    }
    if (
      navigationServiceOptions.pages['product-details'] &&
      navigationServiceOptions.pages['product-details'].featureApps &&
      navigationServiceOptions.pages['product-details'].featureApps.details
    ) {
      productDetailsFAId =
        navigationServiceOptions.pages['product-details'].featureApps.details;
    }
    if (
      navigationServiceOptions.pages['cart'] &&
      navigationServiceOptions.pages['cart'].featureApps &&
      navigationServiceOptions.pages['cart'].featureApps.details
    ) {
      cartFAId = navigationServiceOptions.pages['cart'].featureApps.details;
    }
    if (
      navigationServiceOptions.pages['checkout'] &&
      navigationServiceOptions.pages['checkout'].featureApps &&
      navigationServiceOptions.pages['checkout'].featureApps.details
    ) {
      checkoutFAId =
        navigationServiceOptions.pages['checkout'].featureApps.details ?? '';
    }
  }

  return createRootLocationTransformer(
    INTEGRATOR_ID, // integrator id
    [productListFAId, productDetailsFAId, cartFAId, checkoutFAId], // primaryconsumer id's
  );
}

export {
  getDefaultLogoutRedirectUrl,
  getApiGatewayUrl,
  getAuthProxyUrl,
  getAuthOptions,
  getColaOptions,
  getConfiguratorLinkOptions,
  getCookieServiceOption,
  getCurrentCarlineOptions,
  getCarlineLinkServiceConfig,
  getDisclaimerManagerOptions,
  getDpuApiGatewayUrl,
  getMarketContextApiGatewayUrl,
  getEnvConfigOptions,
  getGfaServiceConfig,
  getI18nOptions,
  getOneGraphOptions,
  getLocaleOptions,
  getMarketContextServiceOptions,
  getNavigationOptions,
  getNumberFormatterOptions,
  getPreviewToken as __getPreviewToken,
  getRenderModeOptions,
  getTrackingOptions,
  getVueFormatterOptions,
  prepareWindow,
  getStateManagerConfig,
  gfaServicesDefaultConfiguration,
  getHistoryServiceConfig,
};
